<template>
    <div>
        <a-button type="primary" @click="btnclick">
            Primary
        </a-button>
        <Rich  v-bind:value.sync="brief"></Rich>
    </div>
</template>
<script>
    //import http from "@/Plugin/Http.js";
    import Rich  from "@/components/RichEditor.vue";
    export default {
        name: "",
        data() {
            return {
                brief:'<h1>33333</h1>'
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            btnclick: function () {
                console.log(this.brief);
            } 
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Rich
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>